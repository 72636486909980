import Link from "next/link";

export function Footer() {
  return (
    <footer className=" mt-16 md:mt-32  pb-10">
      <div className="w-full relative flex justify-center">
        <hr className="border-t border-black w-[85%]" />
        <div className="absolute  left-[7.5%] flex pt-5">
          <Link href="/" className=" flex ">
            <div className="font-bold text-white bg-black cursor-pointer hover:bg-hover-black w-8 h-8 items-center  justify-center flex  rounded">
              K
            </div>
            <div className="font-bold ml-3 text-black cursor-pointer hover:text-hover-black mt-1 ">
              Klarman
            </div>
          </Link>
        </div>
        <div className="absolute  right-[7.5%] mt-6 ">
          <Link
            href="https://airtable.com/apptQRcMxe1V2DXMh/pagvAOzAferbVGcKa/form"
            target="_blank"
            className="text-black   hover:underline"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}
